import "./Utility.scss";
import React from "react";

const Utility = () => (
  <section className="mtmb-utility">
    <div className="mtmb-utility__inner">
        <div className="mtmb-utility__header">
          <h1 className="mtmb-header-2">Official Rules for the ST~GERMAIN® Make the Moment Bloom Sweepstakes</h1>
          <span className="mtmb-fancy-spacer"></span>
          <p className="mtmb-body">No purchase is required to enter.</p>
        </div>
        <div className="rich-text">
          <h3 className="mtmb-header-3">1. ELIGIBILITY:</h3>
          <p className="mtmb-large-body">The Sweepstakes is open only to legal residents of the United States excluding Colorado who are at least twenty-one (21) years old. Employees of Arrowhead Promotion & Fulfillment Co., Inc. (“Administrator”), Bacardi U.S.A., Inc. (“Sponsor”), or any other alcohol beverage company, their respective distributors, wholesalers, retailers (including bartenders/staff), or licensees, as well as the immediate family (spouse, parents, siblings, and children) and household members of those employees, are not eligible to enter. The Sweepstakes is void in Colorado, Puerto Rico, the U.S. Virgin Islands, U.S. Military installations in foreign countries, all other U.S. territories, and possessions, and wherever else restricted or prohibited by law.</p>
          <h3 className="mtmb-header-3">2. ENTRY PERIOD:</h3>
          <p className="mtmb-large-body">The Entry Period begins at 9:00 AM ET on December 2, 2020 and ends at 11:59 PM ET on February 28, 2021 (the “Entry Period”). Administrator’s computer is the official time-keeping device for the Sweepstakes.</p>
          <h3 className="mtmb-header-3">3. HOW TO ENTER:</h3>
          <p className="mtmb-large-body">Visit www.mtmb.stgermain.fr and complete the entry form to receive one (1) entry. There is a limit of one (1) entry per person. The use of multiple e-mail addresses or numbers, an agency, or any automated system to enter is strictly prohibited, and Sponsor reserves the right to disqualify any entries received through such methods. By entering, you indicate your full agreement to these Official Rules and Sponsor’s decisions regarding the Sweepstakes, which are final and binding. Winning a prize is contingent upon fulfilling all requirements in these Official Rules.</p>
          <h3 className="mtmb-header-3">4. DRAWING:</h3>
          <p className="mtmb-large-body">On or about the following dates, Sponsor will select five (5) potential winners in a random drawing of all entries received during the previous calendar month: January 1, 2021; February 1, 2021; and March 1, 2021. The odds of being selected depend on the number of eligible entries received during a given month. Entries that are not selected in a drawing will not roll over into future drawings. Sponsor will attempt to contact the potential winner within five (5) business days of the drawing, using the contact information on the entry form.</p>
          <h3 className="mtmb-header-3">5. Potential Winner Requirements:</h3>
          <p className="mtmb-large-body">A potential winner may be required to sign a declaration of eligibility and liability/publicity release. If a potential winner fails to sign and return these documents within the required time, an alternate potential winner may be selected in his/her place in a random drawing of all remaining entries received. Only three (3) alternate potential winners may be contacted.</p>
          <h3 className="mtmb-header-3">6. Prizes:</h3>
          <p className="mtmb-large-body">Each winner – five (5) per month – will receive a ST~GERMAIN Gift Box, including a set of two Gold Rim Glasses, the How to Spritz French Fluently book, one Large Carafe, a set of two metal stirrers, a set of four steel spoon straws, and a Phillipe Ashley Chocolate Box. Any prize details not specified above will be determined by Sponsor. A prize may not be transferred prior to award. A winner may not request cash or a substitute prize; however, Sponsor reserves the right to substitute a prize with another prize of equal or greater value if the prize is not available for any reason. No alcohol will be awarded as part of any prize.</p>
          <h3 className="mtmb-header-3">7. General Conditions:</h3>
          <p className="mtmb-large-body">In the event that the operation of the Sweepstakes is impaired in any way, Sponsor may, in its sole discretion, either (a) modify the sweepstakes to address the impairment or (b) award the prizes at random from among the eligible entries received up to the time of the impairment. Sponsor reserves the right, in its sole discretion, to disqualify any individual who tampers with the Sweepstakes, violates these Official Rules, or acts in a disruptive manner. Sponsor reserves the right to seek damages from any person who seeks to undermine the legitimate operation of the Sweepstakes. Failure by Sponsor to enforce any term of these Official Rules shall not constitute a waiver of that term. Proof of sending any communication to Sponsor by mail shall not be deemed proof of receipt of that communication by Sponsor. The Sweepstakes is subject to federal, state, and local laws and regulations and is void where prohibited.</p>
          <h3 className="mtmb-header-3">8. Release and Limitations of Liability:</h3>
          <p className="mtmb-large-body">By entering, you agree to release and hold harmless Sponsor, its parents, subsidiaries, affiliates, and each of their respective officers, directors, employees, and agents (the “Released Parties”) from and against any claim or cause of action arising out of participation in the Sweepstakes or receipt or use of any prize. You further agree that in any cause of action, in no event shall the Released Parties be liable for attorney’s fees. You waive the right to claim any damages whatsoever, including, but not limited to, punitive, consequential, direct, or indirect damages.</p>
          <h3 className="mtmb-header-3">9. Privacy:</h3>
          <p className="mtmb-large-body">Any information you submit as part of the Sweepstakes will be treated in accordance with Sponsor’s <a href="www.bacardi.com/us/privacy-policy">Privacy Policy</a>.</p>
          <h3 className="mtmb-header-3">10. Disputes:</h3>
          <p className="mtmb-large-body">You agree that any disputes, claims, and causes of action arising out of, or connected with, the Sweepstakes or any prize awarded shall be resolved individually, without resort to any form of class action, and exclusively by the appropriate court located in Florida. All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules, your rights and obligations, or the rights and obligations of the Sponsor in connection with the Sweepstakes, shall be governed by, and construed in accordance with, the laws of Florida, without giving effect to any choice of law or conflict of law rules (whether of Florida or any other jurisdiction), which would cause the application of the laws of any jurisdiction other than Florida.</p>
          <h3 className="mtmb-header-3">11. Winner Information:</h3>
          <p className="mtmb-large-body">For the name of the winners, send a self-addressed stamped envelope to Arrowhead Promotion & Fulfillment Co. Inc., Att: ST~GERMAIN® Make the Moment Bloom Sweepstakes, P.O. Box 2726Q Grand Rapids, MN 55745-2726. Requests must be received by April 1, 2021.</p>
          <h3 className="mtmb-header-3">12. Sponsor:</h3>
          <p className="mtmb-large-body">The Sweepstakes is sponsored by Bacardi U.S.A., Inc., 2701 Le Jeune Road, Coral Gables, FL 33134. No purchase is necessary to enter. Open US residents except Colorado, 21 or older. Void in CO, PR, the U.S. Virgin Islands, U.S. Military installations in foreign countries, all other U.S. territories, and possessions, and wherever else restricted or prohibited by law. Ends: 2/28/21. For Official Rules, www.mtmb.stgermain.fr.  Sponsored by Bacardi U.S.A., Inc.</p>
        </div>
    </div>
  </section>
)

export default Utility;
